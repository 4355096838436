
  
  #nav-list {
    position: fixed;
    top: 0;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #378CE7;
    z-index: 10;
    align-content: center;
    justify-content: center;
    display: flex;
  }

ul {

  list-style-type: none;
}
  
  #nav-element {
    float: left;
    background-color: none;
    align-self: center;
  }

  #left-elements{
    margin-right:auto;
    display: flex;
    gap: 20px;
  }

  #right-elements{
    margin-left: auto;
    display:flex;
    gap: 0;
  }
  
  #nav-button-primary {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    align-self: center;
    justify-self: center;
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    transition: color .5s, background-color .5s;
  }
  
  /* Change the link color to #111 (black) on hover */
  #nav-button-primary:hover {
    background-color: #DFF5FF;
    color: black;
  }

  #nav-button-secondary {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    align-self: center;
    justify-self: center;
    background-color: #DFF5FF;
    border: none;
    outline: none;
    transition: color .5s, background-color .5s;
  }

  /* Change the link color to #111 (black) on hover */
  #nav-button-secondary:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: white !important;

  }

img {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  height: auto;
  width: 150px;
  align-self: center;
}

  #header-image{
    background-color: white;
    height: 100%;
    align-self: center;
    display:flex;
  }

  #card-label{
    color: black;
    font-size: 14px;
    left: 0px;
    top: -15px;
    position: absolute;
  }

  .input-parent{
    justify-content: space-around;
    display: flex;
  }

  .form-input{
    font-family: 'Lucida Sans Regular'; 
    border: 0px solid #DFF5FF;
    outline: none;
    box-shadow: 0px 2px 6px rgb(0,0,0,.4);
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    transition: border .5s;
    font-family: 'Lucida Sans Regular'; 
  }

.form-input:hover{
  border: 3px solid #DFF5FF;
}

  .form-input:focus{
    border: 3px solid #378CE7;
  }

  #complete-label{
    color: black;
    margin: 0px;
    padding: 15px;
  }

  #nav-button-dropdown{
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    align-self: center;
    justify-self: center;
    background-color: white;
    border: none;
    outline: none;
    width: 100%;
    box-shadow: 0px 2px 6px rgb(0,0,0,.4);
    transition: color .5s, background-color .5s;
  }

  #nav-button-dropdown:hover {
    background-color: #DFF5FF;
    color: black;
  }


#input-field{
  display: block;
  position: relative;

}

#fields-label{
  padding-bottom: 50px;
  margin: 0px;
  color: black;
}

#generate-button, #downloadButton, #saveButton{
  padding: 15px;
  margin-top: 25px;
  background-color: #378CE7;
  box-shadow: 0px 2px 5px rgba(0,0,0, .4);
  border-radius: 100px;
  border: 2px solid #DFF5FF;
  color: white;
  font-size: 20px;
  transform: scale(1);
  transition: background-color .5s, border .5s, transform .5s, color .5s;
}

#generate-button:hover, #downloadButton:hover, #saveButton:hover{
  transform: scale(1.05);
  background-color: #DFF5FF;
  border: 2px solid #DFF5FF;
  color: black;
}

  #qr-selector-button{
    font-family: 'Lucida Sans Regular'; 
    min-width: 100px;
    min-height: 120px;
    border: 3px solid #378CE7;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0, .4);
    border-radius: 5px;
    background-color: rgba(0,0,0,0);
    transform: scale(1);
    margin: 5px;
    transition: border-color .5s, background-color .5s, transform .5s;
  }

#qr-block{
  box-shadow: 0px 2px 10px rgb(0,0,0,.4);
  border-radius: 20px;
  padding: 50px;
  margin-top: 75px;
}

  #qr-selector-button:hover{
    border-color: #DFF5FF;
    background-color: #DFF5FF;
    transform: scale(1.10);
  }

  #qr-selector-button:active{
    border-color: #378CE7;
    background-color: #DFF5FF;
    transform: scale(1.15);
  }
  #qr-selector-button.selector-active{
    border-color: #378CE7;
    background-color: #DFF5FF;
    transform: scale(1.15);
  }

  #web-button{
    padding: 15px;
    border-radius: 100px;
    background-color: #FFFFFF ;
    border: 2px solid #DFF5FF;
    color: #686868 ;
    transition: color .5s, background-color .5s, border-color .5s;
  }

  #web-button:hover{
    background-color: #DFF5FF ;
    color: black ;
    border-color: #5356FF;
  }

  #form-box{
    height: 0px;
    border-radius: 20px;
    background-color: white;
    border: 3px solid #378CE7;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0, .4);
    transition: height .2s;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  #button-holder{
    padding-bottom: 25px;
  }

  .btn-selected{
    border-color: #378CE7;
    background-color: #DFF5FF;
    transform: scale(1.15);
  }
  /* The dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
  }

  .query-active{
    opacity: 100%;
    transition: opacity .2s;
  }

  .query-inactive{
    opacity: 0%;
  }
  
  /* Dropdown button */
  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }
  
  /* Add a red background color to navbar links on hover */
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

