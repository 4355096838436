body {
  margin: 0;
	font-family: 'Lucida Sans Regular'; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button input{
  font-family: 'Lucida Sans Regular' !important; 
}

code {
	font-family: 'Lucida Sans Regular',
    monospace;
}

@font-face {
  font-family: 'Lucida Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Regular'), url('./resources/LSANS.woff') format('woff');
  }